/* eslint-disable default-case */
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Toaster } from "react-hot-toast";
import {
  FilterListRounded,
  SortRounded,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import {
  GetLeadsByUser,
  GetNewLeads,
  GetRedirectedLeads,
  GetRescheduledLeads,
  GetTodaysLeads,
} from "../../data/repos/userRepo";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import ScheduledCard from "../../components/scheduledCard";
import TaskCard from "../../components/TaskCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  InputAdornment,
  Typography,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import { DateTimeFormatter } from "../../utls/dateTimeUtls";
import { getAssignedDate, formatDate } from "../../utls/dateTimeUtls"
import { renderScheduleStatusChip, getLatestComment } from "../plans/utils/scheduledUtilis";

const ManageTask = ({ userId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  


  const [page, setPage] = useState(() =>
    parseInt(searchParams.get('page')) || 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(() =>
    parseInt(searchParams.get('rows')) || 100
  );
  const [activeFilter, setActiveFilter] = useState(() =>
    searchParams.get('filter') || 'total'
  );
  const [searchTerm, setSearchTerm] = useState(() =>
    searchParams.get('search') || ''
  );

  // States for different lead types
  const [totalLeads, setTotalLeads] = useState([]);
  const [pendingLeads, setPendingLeads] = useState([]);
  const [scheduledCalls, setScheduledCalls] = useState([]);
  const [todaysLeads, setTodaysLeads] = useState([]);
  const [redirectedLeads, setRedirectedLeads] = useState([]);
  const [hasMergedPastCalls, setHasMergedPastCalls] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);

  const [sortingOrder, setSortingOrder] = useState("desc");
  const [sortField, setSortField] = useState("name");
  
  const [hasPendingBeenUpdated, setHasPendingBeenUpdated] = useState(false);

  const filterOptions = [
    { id: 'dashboard', label: 'Dashboard', count: dashboardData.length } ,
    { id: 'total', label: 'Total Assigned Leads', count: totalLeads.length },
    { id: 'pending', label: 'Pending Leads', count: pendingLeads.length },
    { id: 'scheduled', label: 'Scheduled Calls', count: scheduledCalls.length },
    // { id: 'today', label: "Today's Lead", count: todaysLeads.length },
    { id: 'redirected', label: 'Redirected Lead', count: redirectedLeads.length }
  ];

  
  const { data: totalLeadsData, isLoading: isTotalLoading } = useQuery(
    'leads',
    GetLeadsByUser,
    {
      onSuccess: (data) => {
        setTotalLeads(data?.responsedata || [])
        
        console.log("Total assigned's leads updated:", data?.responsedata);
      }
    }
  );
  const { data: scheduledCallsData, isLoading: isScheduledLoading } = useQuery(
    'rescheduledleads',
    GetRescheduledLeads,
    {
      onSuccess: (data) => {
        console.log('Raw scheduled calls data:', data?.responsedata);
        const newScheduledCalls = data?.responsedata || [];

        const processedCalls = newScheduledCalls.map(call => ({
          ...call,
          updates: {
            ...call.updates,
            rescheduledate: call.updates?.rescheduledate ?
              new Date(call.updates.rescheduledate).toISOString().split('T')[0] :
              null
          }
        }));

        setScheduledCalls(processedCalls);
        console.log('Processed scheduled calls:', processedCalls);
      }
    }
  );
  const { data: pendingLeadsData, isLoading: isPendingLoading } = useQuery(
    'newleads',
    GetNewLeads,
    {
      onSuccess: (data) => {

        if (!hasPendingBeenUpdated) { 
          setPendingLeads(data?.responsedata || [])
          
          console.log("Initial pending leads set:", data?.responsedata);

        }
       }
      
    }
  );

 
  // Add Today's Leads query
  const { data: todaysLeadsData, isLoading: isTodaysLeadsLoading } = useQuery(
    'todaysleads',
    GetTodaysLeads,
    {
      onSuccess: (data) => {
        setTodaysLeads(data?.responsedata || []);
        console.log("Today's leads updated:", data?.responsedata);
      }
    }
  );

  const { data: redirectedLeadsData, isLoading: isRedirectedLoading } = useQuery(
    'redirectedleads',
    GetRedirectedLeads,
    {
      onSuccess: (data) => {
        setRedirectedLeads(data?.responsedata || [])
        console.log(" Redirected:", data?.responsedata);
      }
    }
  );

  const normalizeDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  };


  useEffect(() => {
    if (!isPendingLoading && !isScheduledLoading && scheduledCalls.length > 0) {


      const today = normalizeDate(new Date());
  
      const isToday = (dateString) => {
        const normalizedDate = normalizeDate(dateString);
        return normalizedDate && normalizedDate.getTime() === today.getTime();
      };
  
      const isPast = (dateString) => {
        const normalizedDate = normalizeDate(dateString);
        return normalizedDate && normalizedDate < today;
      };
  
      // Log the data for debugging
      // console.log('Processing scheduled calls:', scheduledCalls);
      // console.log('Current date:', today);
  
      // Process scheduled calls
      const processedCalls = scheduledCalls.map(call => ({
        ...call,
        normalizedDate: normalizeDate(call.updates?.rescheduledate)
      }));
  
      const pastScheduledCalls = processedCalls.filter(call =>
        call.normalizedDate && isPast(call.updates?.rescheduledate)
      );
  
      const currentAndUpcomingCalls = processedCalls.filter(call =>
        call.normalizedDate && !isPast(call.updates?.rescheduledate)
      );
  
      // console.log('Past calls:', pastScheduledCalls);
      // console.log('Current and upcoming:', currentAndUpcomingCalls);
  
      // Update pending leads with past scheduled calls
      setPendingLeads(prevPendingLeads => {
        const existingIds = new Set(prevPendingLeads.map(lead => lead.id));
        const newPastCalls = pastScheduledCalls.filter(call => !existingIds.has(call.id));
        const updatedPendingLeads = [...prevPendingLeads, ...newPastCalls];
        // console.log('Updated pending leads:', updatedPendingLeads);
        return updatedPendingLeads;
      });
      setHasPendingBeenUpdated(true);
      // Update scheduled calls
      setScheduledCalls(currentAndUpcomingCalls);
  
      // Process dashboard data
      const todayScheduledCalls = currentAndUpcomingCalls.filter(call =>
        isToday(call.updates?.rescheduledate)
      );
  
      const todayLeads = todaysLeads.filter(lead => lead.assignedon);
  
      const combinedDashboardData = [
        ...todayScheduledCalls,
        ...todayLeads
      ];
  
      // console.log('Dashboard data:', combinedDashboardData);
      setDashboardData(combinedDashboardData);
    }
  }, [scheduledCalls,  isPendingLoading, isScheduledLoading,]);
  // console.log('Dashboard data:', dashboardData);
  useEffect(() => {
    setHasPendingBeenUpdated(false);
  }, [activeFilter]);

  const getFilteredData = () => {
    let dataToFilter = [];
    switch (activeFilter) {
      case 'total':
        dataToFilter = totalLeads;
        break;
      case 'pending':
        dataToFilter = pendingLeads;
        break;
      case 'scheduled':
        dataToFilter = scheduledCalls;
        break;
      // case 'today':
      //   dataToFilter = todaysLeads;
      //   break;
      case 'redirected':
        dataToFilter = redirectedLeads;
        break;
      case 'dashboard':
        dataToFilter = dashboardData;
        break;
      default:
        dataToFilter = totalLeads;
    }

    if (!searchTerm) return dataToFilter;

    const searchTermLower = searchTerm.toLowerCase().trim();

    return dataToFilter.filter(item => {
      const nameMatch = item.name?.toLowerCase().includes(searchTermLower);
      const mobileMatch = item.mobile?.toString().includes(searchTermLower);
      const sourceMatch = item.source?.toLowerCase().includes(searchTermLower);
      const statusMatch = item.statusname?.toLowerCase().includes(searchTermLower);
      const commentsMatch = item.updates?.comments?.toLowerCase().includes(searchTermLower);

      return nameMatch || mobileMatch || sourceMatch || statusMatch || commentsMatch;
    });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setPage(0); 
  };

  const handleFilterClick = (filterId) => {
    setActiveFilter(filterId);
    setPage(0);
  };

  const handleFilterButtonClick = () => {
    const newSortingOrder = sortingOrder === "desc" ? "asc" : "desc";
    setSortingOrder(newSortingOrder);

    let dataToSort = [...getFilteredData()];

    dataToSort.sort((a, b) => {
      const nameA = (a.name || "").toLowerCase();
      const nameB = (b.name || "").toLowerCase();

      if (newSortingOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    
    switch (activeFilter) {
      case 'total':
        setTotalLeads(dataToSort);
        break;
      case 'pending':
        setPendingLeads(dataToSort);
        break;
      case 'scheduled':
        setScheduledCalls(dataToSort);
        break;
      // case 'today':
      //   setTodaysLeads(dataToSort);
      //   break;
      case 'redirected':
        setRedirectedLeads(dataToSort);
        break;
    }
  };

  const getDisplayText = (value, type) => {
    if (!value || value === "") {
      switch (type) {
        case 'name':
          return 'No Name';
        case 'phone':
          return 'No Phone';
        case 'count':
          return '0';
        case 'date':
          return 'No Date';
        case 'status':
          return 'No Status';
        case 'followup':
          return 'Call Not Scheduled';
        case 'New lead':
          return 'New lead';
        default:
          return 'N/A';
      }
    }
    return value;
  };

  const handleRowClick = (id) => {
    navigate(`/u/${userId}/task/lead/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('page', page.toString());
    newSearchParams.set('rows', rowsPerPage.toString());
    newSearchParams.set('filter', activeFilter);
    if (searchTerm) {
      newSearchParams.set('search', searchTerm);
    }

    navigate(
      {
        pathname: location.pathname,
        search: newSearchParams.toString()
      },
      { replace: true }
    );
  }, [page, rowsPerPage, activeFilter, searchTerm, navigate, location.pathname]);
 

  const isLoading = isTotalLoading || isPendingLoading || isScheduledLoading || isTodaysLeadsLoading || isRedirectedLoading;
  return (
    <Box m="10px 15px">
      <Toaster position="bottom-right" reverseOrder={false} />

      <Box m="0px 10px" sx={{
        border: '1px solid lightgrey',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      }}>
        <Box sx={{
          backgroundColor: '#f5f5f5',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflowX:'auto'
        }}>
          <Box display="flex" gap={1}>
            {filterOptions.map((option) => (
              <Button
                key={option.id}
                onClick={() => handleFilterClick(option.id)}
                sx={{
                  backgroundColor: activeFilter === option.id ? 'Black' : 'transparent',
                  color: activeFilter === option.id ? 'white' : 'Black',
                  '&:hover': {
                    backgroundColor: activeFilter === option.id ? 'Black' : 'rgba(0, 0, 0, 0.04)',
                  },
                  textTransform: 'none',
                  px: 3,
                  borderRadius: '8px',
                  fontWeight: activeFilter === option.id ? 600 : 400,
                }}
              >
                {option.label}
                <Chip
                  size="small"
                  label={option.count}
                  sx={{ ml: 1, backgroundColor: activeFilter === option.id ? 'white' : '#666', color: activeFilter === option.id ? 'Black':'white' }}
                />
              </Button>
            ))}
          </Box>

          {/* <TextField
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchTerm('')}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              width: 250,
              backgroundColor: 'white',
              borderRadius: '8px'
            }}
          /> */}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            // '& > div': {
            //   marginTop: '10px',
            // },
            marginX: '5px',
            padding: '10px',
          }}
        >
          <TextField
            fullWidth
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchTerm}
            sx={{
              '& .MuiInputLabel-root': {
                color: '#333333',
              },
              '& input': {
                color: '#000000',
                borderRadius: '15px',
                height: '10px',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                backgroundColor: '#f6f6f6',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
          <IconButton
            onClick={handleFilterButtonClick}
            style={{ color: '#000000' }}
            title={`Sort by name ${sortingOrder === "desc" ? "ascending" : "descending"}`}
          >
            {sortingOrder === "desc" ? (
              <FilterListRounded />
            ) : (
              <SortRounded />
            )}
          </IconButton>
        </Box>

        <Box height="60vh">
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : getFilteredData().length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" sx={{ backgroundColor: '#ffffff' }}>
              <Typography variant="h6" color="black">
                {searchTerm ? `No results found for "${searchTerm}"` : 'No data available for selected filter'}
              </Typography>
            </Box>
          ) : (
                <TableContainer component={Paper}
                  sx={{
                    height: "100%",

                    backgroundColor: "#ffffff",
                    borderRadius: '0 !important',
                    // borderTopLeftRadius:'12px',
                    // borderTopRightRadius:'12px',
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid black",
                    },
                    "& thead .MuiTableCell-root": {
                      color: "#FFFFFF",

                    },
                    "& tbody .MuiTableCell-root": {
                      color: "black",
                    },
                  }}
                >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                        <TableCell sx={{ minWidth: 100, width: "10%" }}>Name</TableCell>
                        <TableCell sx={{ minWidth: 100, width: "10%" }}>Phone</TableCell>
                        <TableCell sx={{ minWidth: 100, width: "10%" }}>Follow-up Count</TableCell>
                        <TableCell sx={{ minWidth: 100, width: "10%" }}>Assigned Date</TableCell>
                        <TableCell sx={{ minWidth: 100, width: "10%",textAlign:'center' }}>Status</TableCell>
                        <TableCell sx={{ minWidth: 100, width: "10%" }}>Next Follow-up Date</TableCell>
                        <TableCell sx={{ minWidth: 100, width: "10%" }}>Last Interaction Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredData()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        hover
                        onClick={() => handleRowClick(row.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{getDisplayText(row.name, 'name')}</TableCell>
                        <TableCell>{getDisplayText(row.mobile, 'phone')}</TableCell>
                        <TableCell>{getDisplayText(row.followupcount?.Int64, 'count')}</TableCell>
                        <TableCell>{getAssignedDate(row)}</TableCell>
                        <TableCell>
                          {activeFilter === 'scheduled' ? (
                            renderScheduleStatusChip(row.updates?.rescheduledate)
                          ) : (
                            <Chip
                              label={getDisplayText(row.statusname, 'status')}
                              sx={{
                                backgroundColor: '#e0e0e0',
                                color: 'black',
                                width: '100%'
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>{getDisplayText(formatDate(row.updates?.rescheduledate), 'followup')}</TableCell>
                        <TableCell>{getDisplayText(row.last_updated_on, 'New lead')}</TableCell>
                       {/* {renderCommentCell(row)} */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={getFilteredData().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "white",
              backgroundColor: "black",
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
              "& .MuiSelect-icon": {
                color: "white !important",
              },
              "& .MuiTablePagination-toolbar": {
                borderTop: "1px solid black",
                borderRadius: "30px",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManageTask;