import React, { useEffect, useState, Suspense } from "react";
  import {
    Drawer,
    Box,
    IconButton,
    Typography,
    useTheme,
    useMediaQuery,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";

const DocumentDrawer = ({ open, onClose, title, children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: isSmallScreen ? "100%" : "650px",
          height: "100%",
          overflow: "hidden",
          backgroundColor: "white",
          borderBottomLeftRadius: "1rem",
          borderTopLeftRadius: "1rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // backgroundColor: "red",
        }}
      >
        <Box
          sx={{
            paddingInline: 2,
            paddingInlineStart:4,
            paddingTop:2, 
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // backgroundColor: "purple",
          }}
        >
          <Typography variant="h4" fontWeight="bold" color="black">
            {title}
          </Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            p: 2,
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DocumentDrawer;
